import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class ProfileService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getSharedProfiles(args) {
      return axiosService.axiosIns.get(this.jwtConfig.getSharedPatients, { params: args })
    }

    getSharedProfilesChart(args) {
      return axiosService.axiosIns.get(this.jwtConfig.getSharedPatientsCharts, { params: args })
    }

    getSharedProfilesChartStaff(args) {
      return axiosService.axiosIns.get(this.jwtConfig.getSharedPatientsChartsStaff, { params: args })
    }

    getSharedProfilesChartActions(args) {
      return axiosService.axiosIns.get(this.jwtConfig.getSharedPatientsChartsActions, { params: args })
    }

    getSharedProfilesChartStages(args) {
      return axiosService.axiosIns.get(this.jwtConfig.getSharedPatientsChartsStage, { params: args })
    }

    getFAQ(args) {
      return axiosService.axiosIns.get(this.jwtConfig.getFAQ, { params: args })
    }

    getFAQCategories(args) {
      return axiosService.axiosIns.get(this.jwtConfig.getFAQCategories, { params: args })
    }

    getSharedProfilesExcel(args) {
      return axiosService.axiosIns.get(this.jwtConfig.getSharedPatientsExcel, {
        params: args,
        headers: {
          'Content-Disposition': 'attachment; filename="Shared & Connect.xlsx"',
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arraybuffer',
      })
    }

    getAllElementCatalog(args) {
      return axiosService.axiosIns.get(this.jwtConfig.getAllElementCatalog, { params: args })
    }

    getCallCalendar(args) {
      return axiosService.axiosIns.get(this.jwtConfig.getCallCalendar, { params: args })
    }

    getSharedPatientChangelog(args) {
      return axiosService.axiosIns.get(this.jwtConfig.getSharedPatientLog, { params: args })
    }

    getSharedPatientFeedback(args) {
      return axiosService.axiosIns.get(this.jwtConfig.getSharedPatientComments, { params: args })
    }

    addComment(args) {
      return axiosService.axiosIns.post(this.jwtConfig.addComment, { ...args })
    }

    addPatientAction(args) {
      return axiosService.axiosIns.post(this.jwtConfig.addSharedPatientAction, { ...args })
    }

    setEligibility(args) {
      return axiosService.axiosIns.post(this.jwtConfig.setEligibility, { ...args })
    }
}

function useProfileService() {
  const profileService = new ProfileService()

  return {
    profileService,
  }
}

const { profileService } = useProfileService()
export default profileService
